import React from "react";
import logo from "./logo-3.svg";
import twitter from "./twitter.svg";
import profile from "./profile.png";

import "./App.css";

function App() {
  return (
    <div className="App App-header">
      <img src={logo} className="App-logo" alt="" />
      <div>
        <h1 className="text">
          Architecting the next generation of capital market solutions
        </h1>
      </div>
      <div className="link-container">
        <a
          href="https://www.concrete.xyz/"
          className="link"
          target="_blank"
          rel="noreferrer"
        >
          See More
        </a>
        <a
          href="https://www.concrete.xyz/"
          rel="noreferrer"
          target="_blank"
          className="link"
        >
          <img className="profile" src={profile} alt="" />
        </a>
      </div>
      <a className="button text" href="mailto:hello@blueprintfinance.com">
        Contact
      </a>
      <div className="socials">
        <a href="https://twitter.com/Blueprint_DeFi">
          <img src={twitter} className="twitter" alt="" />
        </a>
      </div>
    </div>
  );
}

export default App;
